.footer-section {
    position: relative;
    padding: 40px 0 35px;
    .shape1 {
        position: absolute;
        max-width: 220px;
        right: 0;
        bottom: 0;
        @media (max-width:1700px) {
            max-width: 150px;
        }
        @include breakpoint(max-xl) {
            display: none;
        }
    }
    &::before {
        @include before-set;
        background: $section-bg;
        opacity: .9;
    }
    .container {
        position: relative;
        z-index: 1;
    }
}
.footer-links  {
    li {
        padding: 3px 10px;
        a {
            color: $white;
            &:hover {
                color: $base-color;
            }
        }
    }
}
.social-links {
    li {
        padding: 5px !important;
        a {
            @extend %shadow;
            border: 0 !important;
        }
    }
}

.footer-bottom {
    padding-top: 25px;
    margin-top: 20px;
    border-top: 1px solid rgba($base-color, .1);
    position: relative;
}